<template>
  <div class="flix-row" v-if="begin && end">
    <div class="flix-col-md-5">
      <h3 class="flix-html-h3">{{ $t('message.begin') }}</h3>
      <div class="flix-form-group">
        <select class="flix-form-control" v-model="begin[0][2]" @change="callback([begin, end])" style="display: inline; width: inherit">
          <option v-for="day in options.days" :key="day">{{ day }}</option>
        </select>
        <select class="flix-form-control" v-model="begin[0][1]" @change="callback([begin, end])" style="display: inline; width: inherit">
          <option v-for="month in options.months" :key="month" :value="month">{{ $t('message.monthsNames')[month] }}</option>
        </select>
        <select class="flix-form-control" v-model="begin[0][0]" @change="callback([begin, end])" style="display: inline; width: inherit">
          <option v-for="year in options.years" :key="year" :value="year">{{ year }}</option>
        </select>
      </div>
      <div class="flix-form-group">
        <getTime variant="5" :data="begin[1][0] + ':' + begin[1][1]" :callback="function(d) { setTime(d, begin[1]) }" /> {{ $t('message.oClock') }}
      </div>
    </div>
    <div class="flix-col-md-5">
      <h3 class="flix-html-h3">{{ $t('message.end') }}</h3>
      <div class="flix-form-group">
        <select class="flix-form-control" v-model="end[0][2]" @change="callback([begin, end])" style="dis play: in line; width: inherit">
          <option v-for="day in options.days" :key="day" :value="day">{{ day }}</option>
        </select>
        <select class="flix-form-control" v-model="end[0][1]" @change="callback([begin, end])" style="display: inline; width: inherit">
          <option v-for="month in options.months" :key="month" :value="month">{{ $t('message.monthsNames')[month] }}</option>
        </select>
        <select class="flix-form-control" v-model="end[0][0]" @change="callback([begin, end])" style="display: inline; width: inherit">
          <option v-for="year in options.years" :key="year" :value="year">{{ year }}</option>
        </select>
      </div>
      <div class="flix-form-group">
        <getTime variant="5" :data="end[1][0] + ':' + end[1][1]" :callback="function(d) { setTime(d, end[1]) }"/> {{ $t('message.oClock') }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    getTime () { return import('@/components/assistent/components/assets/getTime') }
  },
  props: {
    date: Object,
    callback: Function
  },
  data () {
    return {
      begin: false,
      end: false,
      options: {
        days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
        months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        years: [new Date().getFullYear(), new Date().getFullYear() + 1, new Date().getFullYear() + 2]
      }
    }
  },
  watch: {
  },
  methods: {
    setTime (t, a) {
      a[0] = t.hour
      a[1] = t.minute
      this.callback([this.begin, this.end])
    },
    getStart () {
      this.begin = this.date.begin.split(' ')
      this.begin[0] = this.begin[0].split('-')
      this.begin[1] = this.begin[1].split(':')
      this.begin[0][0] *= 1
      this.begin[0][1] *= 1
      this.begin[0][2] *= 1
      this.begin[1][0] *= 1
      this.begin[1][1] *= 1
    },
    getEnd () {
      this.end = this.date.end.split(' ')
      this.end[0] = this.end[0].split('-')
      this.end[1] = this.end[1].split(':')
      this.end[0][0] *= 1
      this.end[0][1] *= 1
      this.end[0][2] *= 1
      this.end[1][0] *= 1
      this.end[1][1] *= 1
    }
  },
  mounted () {
    this.getStart()
    this.getEnd()
    this.callback([this.begin, this.end])
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-form-control
    display: inline
</style>
